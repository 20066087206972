export const blankAddr = "0x0000000000000000000000000000000000000000";
export const testAddr = "";
export const V2Url = "https://test.fivexw.com/#/";
export const serverLink = "https://testservice.fivexw.com";
export const FomoActionAddress = "0xE6300167Bf35beF96101C7Da041eC343DfFbD5fC";
//test
export const shareAddress = "0x7fB089474f6D165a847A52cE042294911DC8c1bE";
// export const contractAddr = '0x354599CC24493C99c6D913EAC885B89b877F69aB';
export const contractAddr = [
  "0xF9d3e0916074c9a1F79bD0DbCb926Ae2B69d6bD2",
  "0x354599CC24493C99c6D913EAC885B89b877F69aB",
];
export const scoreContractAddr = "0x8A6D6eD7d4C24bFEE067b8760a2435392aC6726A";
export const actionContractAddr = "0xE6300167Bf35beF96101C7Da041eC343DfFbD5fC";
export const rewardBagAddress = "0xFf8DCb7f356ae204544C25d6FF4e49350AEA09C1";
export const scoreDecimal = 18;
export const tokenOptions = [
  {
    name: "AURA",
    decimal: 18,
    address: "0x0B6F8848fCA58031fd612dDfc26ABA460A4929aE",
    unitDecimal: 0,
    unitName: "",
  },
  {
    name: "HJW",
    decimal: 18,
    address: "0x36E5d9e3Bb9498ac47B8957Dc8d213Ec126B18C1",
    unitDecimal: 0,
    unitName: "",
  },
  {
    name: "MGET",
    decimal: 18,
    address: "0xc79aeecd8eFcEc3EEc1C1ACf437BDfffD17A2315",
    unitDecimal: 0,
    unitName: "",
  },
];
